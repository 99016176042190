import { AxiosResponse } from "axios";
import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { destroy, get, post, put } from "@/application/api";
import { UserLevel } from "@/modules/product/enum/UserLevel";

type IndexResponse = User;

export interface User {
  id?: number;
  email: string;
  name: string;
  clientId?: number;
  clientIds: Record<string, unknown>;
  level: UserLevel;
  canSeeTranslatorOrders: boolean;
}

export const userIndex = (
  data: PaginatedRequest
): Promise<AxiosResponse<PaginatedResponse<IndexResponse[]>>> => {
  return getPaginated("user", data);
};

export const userShow = (
  userId: string
): Promise<AxiosResponse<{ data: User }>> => {
  return get(`user/${userId}`);
};

export const userUpdate = (user: User): Promise<void> => {
  return put(`user/${user.id}`, user);
};

export const userCreate = (user: Omit<User, "id">): Promise<void> => {
  return post(`user`, user);
};

export const userDestroy = (userId: number): Promise<void> => {
  return destroy(`user/${userId}`);
};
