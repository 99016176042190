
import Vue from "vue";
import KSelect from "@/components/crud/fields/KSelect.vue";
import {
  getTranslatedEnumOptions,
  TranslatedEnumOptions,
} from "@/application/util/enum";
import { UserLevel } from "@/modules/product/enum/UserLevel";

interface ComponentData {
  items: TranslatedEnumOptions;
}
export default Vue.extend({
  name: "UserLevelSelect",
  components: { KSelect },
  data: (): ComponentData => ({
    items: [],
  }),
  created() {
    this.items = getTranslatedEnumOptions(UserLevel, "user.userLevel");
  },
});
