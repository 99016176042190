
import Vue from "vue";
import KForm from "@/components/KForm.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import {
  User,
  userCreate,
  userShow,
  userUpdate,
} from "@/modules/user/api/user";
import eventBus from "@/application/eventBus";
import UserLevelSelect from "@/modules/user/components/UserLevelSelect.vue";
import ClientAutocomplete from "@/modules/client/components/ClientAutocomplete.vue";
import { UserLevel } from "@/modules/product/enum/UserLevel";
import KCheckbox from "@/components/crud/fields/KCheckbox.vue";
import axios, { AxiosError } from "axios";

interface ComponentData {
  isLoading: boolean;
  values: User | null;
  isSuccess: boolean;
  UserLevel: typeof UserLevel;
}

export default Vue.extend({
  name: "Form",
  components: {
    KCheckbox,
    ClientAutocomplete,
    UserLevelSelect,
    KTextField,
    KForm,
  },
  data: (): ComponentData => ({
    isLoading: true,
    values: {
      name: "",
      email: "",
      level: UserLevel.Default,
      canSeeTranslatorOrders: true,
      clientId: null,
      clientIds: null,
    },
    isSuccess: false,
    UserLevel: UserLevel,
  }),
  computed: {
    isUpdateForm() {
      return this.$route.params.id !== undefined;
    },
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      async handler(id) {
        if (id !== undefined) {
          try {
            const response = await userShow(id);
            this.values = response.data.data;
          } catch (error: AxiosError | unknown) {
            if (axios.isAxiosError(error) && error.response?.status === 404) {
              this.$router.push({ name: "notFound" });
              return;
            }
            throw error;
          }
        }
      },
    },
  },
  methods: {
    request() {
      if (!this.values) {
        return;
      }

      if (this.isUpdateForm) {
        return userUpdate(this.values);
      }
      return userCreate(this.values);
    },
    handleSuccess() {
      eventBus.$emit("snackbar", this.$t("global.saveSuccess"));

      this.isSuccess = true;
      this.$router.push({
        name: "user.index",
      });
    },
  },
});
